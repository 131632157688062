/**
 * Standaard boilerplate scripts
 */

var addListener = (element, type, callback) => {
    if (element.addEventListener) {
        element.addEventListener(type, callback);
    } else if (element.attachEvent) {
        element.attachEvent('on' + type, callback);
    }
};

/*/ cookiepage settings /*/
function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000));
        var expires = "; expires="+date.toGMTString();
    }
    else var expires = "";
    document.cookie = name+"="+value+expires+"; path=/";
}

(function() {
    var buttonAccept = document.getElementsByClassName('cookiesettings__card--accept')[0],
        buttonDecline = document.getElementsByClassName('cookiesettings__card--decline')[0];

    if (buttonAccept) {
        buttonAccept.onclick = function () {
            createCookie('cookieSet', true, 10000);
            location.reload();
        };

        buttonDecline.onclick = function () {
            createCookie('cookieSet', 'declined', 10000);
            location.reload();
        };
    }
})();

// menu
var menuOnLoad = () => {
    var ulHasChildren = document.getElementsByClassName('menu__list');
    for (var i = 0; i < ulHasChildren.length; i++) {
        if (ulHasChildren[i].childNodes.length === 2) {
            ulHasChildren[i].classList.add('has-children');
        }
    }

    var menuChildToggle = document.getElementsByClassName('has-children');
    for (let k = 0; k < menuChildToggle.length; k++) {
        menuChildToggle[k].addEventListener('click', function () {
            var menu = menuChildToggle[k].childNodes;
            if (menuChildToggle[k].classList) {
                menuChildToggle[k].classList.toggle('open-parent');
                menu[1].classList.toggle('open');
            }
        })
    }
};
if (window.attachEvent) {
    window.attachEvent('onload', menuOnLoad);
}
else if (window.addEventListener) {
    window.addEventListener('load', menuOnLoad, false);
}
else {
    document.addEventListener('load', menuOnLoad, false);
}

(() => {
    var isIE = /*@cc_on!@*/false || !!document.documentMode;
    var notificationIE = document.getElementsByClassName('notificationIE')[0];
    var exit = document.getElementsByClassName('notificationIE-exit')[0];
    if (isIE === true) {
        var exitIEFunction = () => notificationIE.parentNode.removeChild(notificationIE);
        notificationIE.classList.add('block');
        exit.onclick = () => exitIEFunction();
    } else {
        notificationIE.parentNode.removeChild(notificationIE);
    }
})();

// (() => {
//     var faqItem = document.getElementsByClassName('faq__question');
//     for (let k = 0; k < faqItem.length; k++) {
//         let faqSibling = faqItem[k].nextElementSibling;
//         faqItem[k].addEventListener('click', () => {
//             if (faqSibling.offsetHeight !== faqSibling.scrollHeight) {
//                 faqItem[k].parentElement.classList.add('active');
//                 faqSibling.style.height = faqSibling.scrollHeight + "px";
//             }
//             else {
//                 faqSibling.style.height = 0;
//                 faqItem[k].parentElement.classList.remove('active');
//             }
//         });
//     }
// })();

(() => {
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) {
    } else {
        var overlay = document.getElementsByClassName('menu-overlay')[0];
        var menuItem = document.getElementsByClassName('menu__list');
        for (var i = 0; i < menuItem.length; i++) {
            let mouseOver = () => overlay.classList.add('active');
            let mouseOut = () => overlay.classList.remove('active');
            if (menuItem[i].childNodes.length === 2) {
                menuItem[i].onmouseover = () => mouseOver();
                menuItem[i].onmouseout = () => mouseOut();
            }
        }
    }
})();

(() => {
    var showall = document.getElementsByClassName('show-all');
    for (let k = 0; k < showall.length; k++) {
        showall[k].addEventListener('click', () => {
            var parent = showall[k].parentNode;
            if (parent.classList) {
                parent.classList.toggle('active');
            }
        })
    }
})();

(() => {
    var tabLink = document.querySelectorAll("[data-tabLink]");
    var tabContent = document.querySelectorAll("[data-tabContent]");
    if(tabLink.length > 1) {
        for (let k = 0; k < tabLink.length; k++) {
            tabLink[k].addEventListener('click', () => {
                for (var l = 0; l < tabLink.length; l++) {
                    tabLink[l].classList.remove('active');
                }
                tabLink[k].classList.add('active');
                for (let i = 0; i < tabContent.length; i++) {
                    var tabLinkAttr = tabLink[k].getAttribute("data-tabLink");
                    var tabContentAttr = tabContent[i].getAttribute("data-tabContent");
                    tabContent[i].style.display = "none";
                    if (tabContentAttr === tabLinkAttr) {
                        tabContent[i].style.display = "flex";
                    }
                }
            });
        }
        document.querySelector("[data-defaultOpen]").click();
    }
})();

var overlayOnLoad = () => {
    document.getElementsByClassName('menu-overlay')[0].setAttribute("style", `height: ${document.body.offsetHeight}px`);
};
if (window.attachEvent) {
    window.attachEvent('onload', overlayOnLoad);
} else if (window.addEventListener) {
    window.addEventListener('load', overlayOnLoad, false);
} else {
    document.addEventListener('load', overlayOnLoad, false);
}
//
// var lastScrollTop = 0;
// window.addEventListener("scroll", function(){
//     var st = window.pageYOffset || document.documentElement.scrollTop;
//     if (document.body.scrollTop > document.getElementsByClassName('topbar')[0].offsetHeight || document.documentElement.scrollTop > document.getElementsByClassName('topbar')[0].offsetHeight) {
//         document.getElementsByClassName("topbarFixed")[0].classList.add('fixed', 'pin-t');
//         document.getElementsByClassName("topbarFixed")[0].style.boxShadow = '0px 1px 8px rgba(0, 0, 0, 0.12)';
//         document.getElementsByClassName("topbarFixedReplace")[0].style.paddingTop = document.getElementsByClassName('topbarFixed')[0].offsetHeight + 'px';
//         document.getElementsByClassName("scrollTop")[0].style.display = "flex";
//
//         if (st > lastScrollTop){
//             document.getElementsByClassName("topbarFixed")[0].classList.remove('transition-up');
//             document.getElementsByClassName("topbarFixed")[0].classList.add('transition-down');
//         } else if (document.getElementById('header').offsetHeight + document.getElementsByClassName('topbar')[0].offsetHeight > st) {
//             document.getElementsByClassName("topbarFixed")[0].classList.remove('transition-up');
//             document.getElementsByClassName("topbarFixed")[0].classList.remove('fixed', 'pin-t');
//             document.getElementsByClassName("topbarFixedReplace")[0].style.paddingTop = '0';
//             document.getElementsByClassName("topbarFixed")[0].style.boxShadow = '0px 0px 0px rgba(0, 0, 0, 0.00)';
//             document.getElementsByClassName("scrollTop")[0].style.display = "none";
//         } else if (st + 10 < lastScrollTop && st > document.getElementById('header').offsetHeight + document.getElementsByClassName('topbar')[0].offsetHeight) {
//             document.getElementsByClassName("topbarFixed")[0].classList.add('transition-up');
//             document.getElementsByClassName("topbarFixed")[0].classList.remove('transition-down');
//         }
//     } else {
//         document.getElementsByClassName("topbarFixed")[0].classList.remove('transition-up');
//         document.getElementsByClassName("topbarFixed")[0].classList.remove('fixed', 'pin-t');
//         document.getElementsByClassName("topbarFixedReplace")[0].style.paddingTop = '0';
//         document.getElementsByClassName("topbarFixed")[0].style.boxShadow = '0px 0px 0px rgba(0, 0, 0, 0.00)';
//         document.getElementsByClassName("scrollTop")[0].style.display = "none";
//     }
//     lastScrollTop = st <= 0 ? 0 : st;
// }, false);

(() => {
    var aHref = document.querySelectorAll('a[href^="#"]');
    for (var k = 0; k < aHref.length; k++) {
        aHref[k].addEventListener('click', function(e) {
            e.preventDefault();

            document.querySelector(this.getAttribute('href')).scrollIntoView({
                behavior: 'smooth'
            });
        });
    }
})();
